<template>
    <div class="page">
        <nav-block></nav-block>
        <header-block></header-block>
        <about-block></about-block>
        <menu-block></menu-block>
        <informations-block></informations-block>
        <footer-block></footer-block>
    </div>
</template>

<script>
import SectionBlock from '../components/SectionBlock.vue'
import HeaderBlock from '../components/HeaderBlock.vue'
import AboutBlock from '../components/AboutBlock.vue'
import MenuBlock from '../components/MenuBlock.vue'
import InformationsBlock from '../components/InformationsBlock.vue'
import FooterBlock from '../components/FooterBlock.vue'
import NavBlock from '../components/NavBlock.vue'

export default {
    components: {
        SectionBlock,
        HeaderBlock,
        AboutBlock,
        MenuBlock,
        InformationsBlock,
        FooterBlock,
        NavBlock
    }
}
</script>

<style>
.page {
    height: 100%;
    width: 100%;

    display: flex;
    flex-flow: column nowrap;

    overflow-y: auto;

    background-color: darksalmon;
}

/* Add space for the navbar */
/* .page>:first-child {
    padding-top: 13em;
} */
.header {
    padding-top: 8em;
}

.content-wrapper {
    min-height: 0px;
    height: calc(100vh - 40px);

    display: flex;
    flex-flow: column nowrap;

    overflow-x: auto;
}

img {
    display: block;
    max-width: 100%;
    height: auto;
    border: 0;
}

a {
    color: inherit;
}

.button {
    vertical-align: middle;
    display: inline-block;
    padding: 1.3em 1.5em 1.2em;
    box-sizing: border-box;
    border: 1px solid #8c4044;
    background: #8c4044;
    color: #fff;
    border-radius: .33em;
    font-size: 1.5rem;
    line-height: 1.2em;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    text-indent: .12em;
    letter-spacing: .12em;
    -o-transition: color .2s cubic-bezier(.23,1,.32,1),border-color .2s cubic-bezier(.23,1,.32,1),background-color .2s cubic-bezier(.23,1,.32,1),-o-transform .15s cubic-bezier(.23,1,.32,1),padding .35s cubic-bezier(.23,1,.32,1);
    transition: color .2s cubic-bezier(.23,1,.32,1),border-color .2s cubic-bezier(.23,1,.32,1),background-color .2s cubic-bezier(.23,1,.32,1),-webkit-transform .15s cubic-bezier(.23,1,.32,1),padding .35s cubic-bezier(.23,1,.32,1);
    transition: color .2s cubic-bezier(.23,1,.32,1),border-color .2s cubic-bezier(.23,1,.32,1),background-color .2s cubic-bezier(.23,1,.32,1),transform .15s cubic-bezier(.23,1,.32,1),padding .35s cubic-bezier(.23,1,.32,1);
}

.button__text {
    position: relative;
    vertical-align: middle;
    display: inline-block;
}
.button:hover {
    border-color: #8c4044;
    background-color: #fff;
    color: #8c4044;
}
.button--white {
    background: #fff;
    color: #8c4044;
}
.button--fixed-width-large {
    min-width: 22em;
    text-align: center;
}
.button--fixed-width {
    min-width: 12em;
    text-align: center;
}
.button--neg {
    padding-top: 1.45em;
    padding-bottom: 1.4em;
    border-color: #fff;
    background: #8c4044;
    color: #fff;
}
.button--phone {
    position: relative;
    overflow: hidden;
}
.button--phone a {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    text-indent: -999px;
}
.button--phone:after {
    vertical-align: middle;
    display: inline-block;
    content: attr(data-label-mobile);
}

.section {
    background: #f4f2f3;
    color: #8c4044;
    
    display: table;
    table-layout: fixed;
    width: 100%;
    padding: 5em 3em;
    box-sizing: border-box;
}

.section--cta {
    color: #fff;
    background: #8c4044;
}
.section__cta--spaced {
    margin-bottom: .75em;
}
.section__cta {
    text-align: center;
}

.section__body {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 25em;
    /* visibility: hidden; */
}

.section__title {
    margin: 0 0 1.5em;
    line-height: 1.35em;
    font-size: 2.5em;
    font-weight: 400;
    font-family: Raleway,sans-serif;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .2em;
    text-indent: .2em;
}

.section__tagline {
    margin: 0 0 1.5em;
    font-size: 2em;
    line-height: 1.35em;
    text-align: center;
    color: #231011;
}
.section__info--spaced {
    margin-top: 5rem;
}
.section__info {
    margin: 1em 0 3em;
    font-size: 1.4em;
    font-style: italic;
    text-align: center;
}
.section__title+.section__tagline {
    margin-top: -1em;
}
.section--cta .section__tagline {
    color: rgba(255,255,255,.66);
}
.section:not(.section--white):not(.section--cta):nth-child(even) {
    background: #f9f7f7;
}
</style>