<template>
    <div class="footer">
        <div class="footer__social">
            <ul class="social">
                <li class="">
                    <a rel="nofollow" title="Rejoignez nous sur Facebook" target="_blank" href="https://www.facebook.com/1919829018319436">
                        <font-awesome-icon class="social__footer-icon" :icon="['fab', 'facebook']" size="3x"/>
                    </a>
                </li>
                <li class="">
                    <a rel="nofollow" title="Retrouvez nous sur Yelp" target="_blank" href="http://www.yelp.com/biz/7OMxRtWo-xp3P86EyyyhdQ">
                        <font-awesome-icon class="social__footer-icon" :icon="['fab', 'yelp']" size="3x"/>
                    </a>
                </li>
                <li class="split">
                    <a rel="nofollow" title="Retrouvez nous sur TripAdvisor" target="_blank" href="https://fr.tripadvisor.be/Restaurant_Review-g187147-d1327112-Reviews-Chez_Nicos-Paris_Ile_de_France.html">
                        <font-awesome-icon class="social__footer-icon" :icon="['fab', 'tripadvisor']" size="3x"/>
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer__company">
            <p class="footer__company-coord">
                <strong>Chez Nicos</strong>
                <span class="footer__company-coord-sep"></span> Crêperie<span class="footer__company-coord-comma">, </span>Snack <span class="footer__company-coord-sep"></span> Paris, France 
            </p>
            <p class="footer__company-coord">
                <a href="tel:+33145872813">Nous contacter</a>
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.footer {
    -ms-flex-negative: 0;
    flex-shrink: 0;
    padding: 5em 0 0;
    overflow: hidden;
    background: rgb(123, 56, 60);
    color: rgb(244, 242, 243);
    text-align: center;
}
.footer__social {
    padding: 0 4em 5em;
}

.footer__company {
    padding: .5em 4em 4em;
    font-size: 1.5em;
}
.footer__company-coord {
    margin: 0 0 1em;
    line-height: 1.5em;
}
.footer__company-coord-sep {
    display: block;
    height: .15em;
}

.footer__company-coord-comma {
    display: block;
    font-size: 0;
    height: 0;
}


.social {
    margin: 0 0 -2.5em;
    padding: 0;
    list-style: none;
    text-align: center;
}
.social__icon {
    font-size: 2.5em;
}
.social a {
    margin-bottom: 2.5em;
    display: inline-block;
    width: 5em;
    height: 5em;
    border: 1px solid;
    border-radius: 100%;
    line-height: 5em;
    text-decoration: none;
}
.social li {
    margin: 0 1.25em;
    display: inline;
}

.social__footer-icon {
    vertical-align: middle !important;
}

@media (min-width: 681px) {
    .footer__company-coord-comma {
        display: inline;
        font-size: 1em;
        height: auto;
    }
}
</style>