<template>
    <nav class="nav__header">
        <div class="nav__header-content">
            <ul class="nav__header-list">
                <li class="nav__header-item">
                    <a class="nav__header-link" href=".">
                        <font-awesome-icon :icon="['fas', 'home']" size="2x"/>
                    </a>
                </li>
                <li class="nav__header-item">
                    <a class="nav__header-link" href="#about">À propos</a>
                </li>
                <li class="nav__header-item">
                    <a class="nav__header-link" href="#menu">Menu</a>
                </li>
                <li class="nav__header-item">
                    <a class="nav__header-link" href="#location">Informations</a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
export default {

}
</script>

<style>
.nav__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 8em;
    box-shadow: 0 0.2em 1em 0 rgba(35,16,17,.1);
    background: #fff;
    z-index: 10;
    font-size: 1.2em;
}

.nav__header-content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    vertical-align: middle;
}

.nav__header-list {
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 1.2em;
    font-weight: 500;
    line-height: 1em;
    letter-spacing: .14em;
    text-transform: uppercase;
    text-align: center;
    
    display: flex;
    align-items: center;
}

.nav__header-item:first-child {
    margin-left: 0;
}
.nav__header-item:last-child {
    margin-right: 0;
}
.nav__header-item {
    margin: 0 1.25em;
    display: inline-block;
    padding: 0.5em 0;
}
.nav__header-item.is-active {
    border-bottom: 0.1rem solid;
}

.nav__header-link {
    text-decoration: none;
}
</style>