<template>
  <div id="app">
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <!-- <router-view/> -->
    <home-container></home-container>
  </div>
</template>

<script>
import HomeContainer from './views/HomeContainer.vue'
export default {
  components: {
    HomeContainer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400&family=Raleway:wght@300;400&display=swap');
/* TODO : Put it in an external css file */
html, body {
  margin: 0;
  height: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 7.25px;
  color: #231011;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
