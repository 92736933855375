<template>
    <section id="menu" class="section section--cta">
        <div class="section__body">
            <h2 class="section__title">Menu</h2>
            <p class="section__tagline">Disponible prochainement...</p>
            <!-- <div class="section__button--container">
                <a class="button button--neg button--fixed-width" href="#">
                    <span class="button__text">Voir la carte</span>
                </a>
            </div>
            <p class="section__info section__info--spaced"> Inclut des options végétariennes et sans lactose. </p> -->
            <h2 class="section__title">À emporter</h2>
            <p class="section__tagline">Commandez et venez chercher sur place</p>
            <div class="section__cta section__cta--spaced">
                <div class="button button--white button--phone button--fixed-width-large" data-label="Appeler le +33 1 45 87 28 13" data-label-mobile="Appeler le +33 1 45 87 28 13" data-event-type="click" data-event-info="phone" data-event-action="takeaway">
                <!-- <div class="button button--white button--phone button--fixed-width-large" data-label="Appeler le +33 1 45 87 28 13"> -->
                    <a href="tel:+33145872813"> Appeler le +33 1 45 87 28 13 </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.section__button--container {
    text-align: center;
    width: 100%;
}
</style>