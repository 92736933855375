<template>
    <header class="header">
        <div class="header__bg"> </div>
        <div>
            <div class="header__company">
                <div class="header__company-logo">
                    <img alt="Chez Nicos" src="~@/assets/logo-cheznicos.jpeg">
                </div>
                <h1 class="header__company-name">Chez Nicos</h1>
                <p class="header__company-tagline">Bienvenue Chez Nicos, l’une des meilleures crêperies de Paris !</p>
                <a class="button" href="#menu">
                    <span class="button__text">Commander</span>
                </a>
            </div>
        </div>
    </header>
</template>

<script>
export default {

}
</script>

<style>
.header {
    position: relative;
    background: #040202;
    box-sizing: border-box;
    padding: 3em 4em;
}
.header__bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: .5;
    background-position: center center;
    background-size: cover;

    background-image: url('~@/assets/header-bg.jpeg');
}

.header__company {
    position: relative;
    z-index: 1;
    text-align: center;
    padding: 6em 0;
}

.header__company>:last-child {
    margin-bottom: 0;
}

.header__company-logo {
    margin: 0 auto 2.66em;
    width: 20em;
    height: 20em;
    padding: 5px;
    background: #fff;
    box-shadow: 0 0.15em 3.5em 0 rgba(0,0,0,.5);
    border-radius: .33em;
}
.header__company-logo img {
    width: 100%;
    height: 100%;
    border-radius: .2em;
}

.header__company-name {
    margin: 0 0 .333em;
    color: #fff;
    line-height: 1.35em;
    font-size: 3em;
    font-weight: 400;
    -webkit-font-feature-settings: "lnum";
    -moz-font-feature-settings: "lnum";
    font-feature-settings: "lnum";
    font-variant-numeric: lining-nums;
    font-family: Raleway,sans-serif;
    letter-spacing: .16em;
    text-indent: .16em;
    text-transform: uppercase;
}

.header__company-tagline {
    margin: 0 0 1.5em;
    font-size: 2.2em;
    line-height: 1.3em;
    color: rgba(244,236,236,.8);
}

.header__company-address, .header__company-name, .header__company-tagline {
    text-shadow: 0 1px 0.1em rgba(0,0,0,.5), 0 0 0.2em rgba(0,0,0,.5);
}
</style>