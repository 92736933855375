<template>
    <section class="section">
        Test
    </section>
</template>

<script>
/*
    TODOs :
    - Add an ID prop
    - add a class

*/
export default {

}
</script>

<style>
.section {
    width: 100%;
    padding: 5em 3em;
    box-sizing: border-box;
}
</style>