<template>
    <section id="about" class="section">
        <div class="section__body">
            <h2 class="section__title">À propos</h2>
            <div class="layout">
                <div class="text">
                    <p>Chez Nicos est votre crêperie située à Paris, à deux pas du Panthéon. <br><br>Chez Nicos, vous pourrez retrouver une cuisine de qualité, rassemblant les plus délicieuses saveurs issues de France.</p> <p>Chez Nicos, votre crêperie, goûtez à de nombreuses préparations faites maison, réalisées avec amour, soin et attention. Testez ainsi nos délicieuses crêpes jambon fromage au goût unique, nos superbes paninis tomates mozzarella ou bien laissez-vous tenter par notre savoureuse crêpe au nutella.<br><br>Nous souhaitons en effet à la crêperie Chez Nicos vous proposer une véritable expérience culinaire dans un cadre chaleureux, agréable et raffiné, tout comme les préparations que vous dégusterez.<br><br>Vivez l’expérience sur place ou à emporter!<br><br>A bientôt Chez Nicos !</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style>
.layout {
    margin: 0 auto;
    max-width: 84em;
}

.text {
    margin: 0 0 3.2rem;
    padding: 0 1.105em;
    font-size: 1.9rem;
    line-height: 1.6em;
    color: #231011;
    text-align: center;
}
.text :first-child {
    margin-top: 0;
}
.text :last-child {
    margin-bottom: 0;
}
.text p {
    margin: 0 0 1.6em;
}
/* .css-loaded .section__body {
    visibility: visible;
} */
</style>